<template>
    <main>
        <article>
          <h2>Success!</h2>
          <div><p>Thanks for taking the time out to check out my portfolio and shoot me a message, I'll follow it up and get back to you soon.</p></div>
          <div><p>If you still wanna go back and check out the rest of the portfolio then just hit the 'home' button in the top corner (or if you're lazy like me you can just <a href="/home">click here!</a>)</p></div>
        </article>
    </main> 
</template>

<script>

export default {
  name: "FormSuccess"
};
</script>