<template>
    <main>
        <article>
          <h2>oh noes &#128546;</h2>
          <div><p>Welp, looks like something went wrong.</p></div>
          <div><p>Look, it's probably my fault, but just in case maybe give it a little while then <a href="/home">go back and try again</a>, or you can send a text to +61488880<b>DAN</b> (for reals) with your details and I'll get in touch as soon as I can.  </p></div>
        </article>
    </main> 
</template>

<script>

export default {
  name: "FormSuccess"
};
</script>